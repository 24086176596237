import React from "react";
import firebase from 'firebase/app';
import sanitizeHtml from "sanitize-html";
import TopRow from "./TopRow";
import Grid from "./Grid";
import Share from "./Share";
import {itemWidth} from "./Utils";

require("firebase/auth");
require("firebase/firestore");

var moment = require('moment');

function getRandomInt (min, max)
{
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

class App extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: 30,
    rowHeight: 32,
    compactType: null,
    preventCollision: true
  };

  static newId() {
    // Alphanumeric characters (sans v)
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
  }

  setupDatabase() {
    // Initialize Firebase
    var config = {
      apiKey: "AIzaSyDRktuIA8t5EsGjUDWRID3lk-t3YEZ18Wc",
      authDomain: "timefiddle-1.firebaseapp.com",
      databaseURL: "https://timefiddle-1.firebaseio.com",
      projectId: "timefiddle-1",
      storageBucket: "timefiddle-1.appspot.com",
      messagingSenderId: "498953621010"
    };
    firebase.initializeApp(config);

    // Initialize Cloud Firestore through Firebase
    var db = firebase.firestore();

    // Disable deprecated features
    db.settings({
      timestampsInSnapshots: true
    });

    return db;
  }

  constructor(props) {
    super(props);

    let initialId = undefined;
    let firstPath = undefined;
    let splitPath = window.location.pathname.split("/");
    if (splitPath.length >= 2) {
      firstPath = splitPath[1];

      // special case for github which has /timefiddle/[currentId]
      if (firstPath === "timefiddle" && splitPath.length >= 3) {
        firstPath = splitPath[2];
      }
    }

    let newPath = undefined;
    if (firstPath && (firstPath.length === 20 || firstPath.length === 22)) {
      initialId = firstPath;
    } else {
      initialId = App.newId();
      if (splitPath.length >= 2 && splitPath[1] === "timefiddle") {
        newPath = "/timefiddle/" + initialId;
      } else {
        newPath = "/" + initialId;
      }
      console.log("setting new path", newPath);
      window.history.replaceState(null, "Title", newPath);
    }

    this.state = {
      currentId: initialId,
      blob: undefined,
      currentInputType: undefined,
      currentInputId: undefined,
      db: this.setupDatabase(),
      showCounter: 0,
      showingShare: false,
      itemWidth: itemWidth
    };

    this.colours = ["#3874e2", "#0f91b3", "#489a2b", "#c19b03", "#ff842e", "#cc51a6", "#ce1c1c", "#f5f5f5"];

    firebase.auth().signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Failed to sign in anonymously", errorCode, errorMessage);
    });

    var self = this;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        console.log("user signed in!", isAnonymous, uid);

        self.initDatabase(initialId);
      } else {
        // User is signed out.
        console.log("user is signed out.");
      }
    });
  }

  initDatabase(initialId) {

    // Uncomment to move a block back and forth.
    //setInterval(() => { this.updateMovingTestItem() }, 3000);

    this.state.db.collection("timelines").doc(this.state.currentId).onSnapshot((doc) => {
      // new schema version?  reload the page!  but avoid infinite loop.

      let data = doc.data();
      if (data) {
        //console.log("snapshot received: ", doc.data().blob.layout[16]);
        let newBlob = this.copyBlob(data);
        this.setState({blob: newBlob, showCounter: this.state.showCounter + 1});
        //console.log(newBlob);
      } else {
        // save initial doc
        //console.log("No initial data, so setting doc.");
        this.state.db.collection('timelines').doc(initialId).set({
          layout: [],
          columnsFirstValues: {
            0: moment().format("MMM D"),
            1: moment().add(1, "d").format("MMM D")
          },
          showTopRow: true,
          locked: false
        }, {merge: true})
        .then(function() {
          console.log("Document successfully written!");
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        });
      }
    });
  }

  copyBlob(blob) {
    return JSON.parse(JSON.stringify(blob));
  }

  onMouseUp(i) {
    //console.log("onMouseUp called");

    this.setState({
      currentInputType: "item",
      currentInputId: i
    });
  }

  onTopRowMouseUp(i, e) {
    e.stopPropagation();

    if (i <= 1) {
      this.setState({
        currentInputType: "toprow",
        currentInputId: i
      });
    }
  }

  clearSelect() {
    //console.log("clearSelect");
    this.setState({
      currentType: null,
      currentInputId: null
    })
  }

  onItemKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  loadItems() {
    let columns = [];
    return [
      ...columns,
      {x: 0, y: 1, w: 2, h: 1, i: "0", text: "first"},
      {x: 0, y: 2, w: 2, h: 1, i: "1", text: "second"},
      {x: 2, y: 3, w: 2, h: 1, i: "2", text: "third"},
      {x: 4, y: 4, w: 2, h: 1, i: "3", text: "fourth"},
      {x: 1, y: 5, w: 2, h: 1, i: "4", text: "fifth"},
    ];
  }

  updateMovingTestItem() {
    if (this && this.state) {
      let newBlob = this.copyBlob(this.state.blob);
      newBlob.layout[13].x = 10 - newBlob.layout[13].x;
      this.setState({blob: newBlob});
    }
  }

  updateDoc(update) {
    return this.state.db.collection('timelines').doc(this.state.currentId).update(update);
  }

  setDocWithId(id, success, failure) {
    this.state.db.collection('timelines').doc(id).set(
      {...this.state.blob, locked: true}, {merge: true})
    .then(function() {
      console.log("Set doc successfully written!");
      success();
    })
    .catch(function(error) {
      console.error("Set doc error: ", error);
      failure();
    });
  }

  updateFeedback(update) {
    return this.state.db.collection('feedback').doc(this.state.currentId).set(update, {merge: true});
  }

  addItemAtLocation(x, y, w, colour) {
    let newItemId = getRandomInt(0, Number.MAX_SAFE_INTEGER).toString();
    colour = colour || (this.state && this.state.lastColour) || this.colours[0];
    let newItem = {x: x, y: y, w: w, h: 1, text: "Untitled", colour: colour};

    let update = {};
    update["layout." + newItemId] = newItem;
    this.updateDoc(update)
    .then(function() { console.log("Document successfully updated!"); });
  }

  addItem() {
    if (this && this.state) {
      const itemCount = Object.keys(this.state.blob.layout).length;

      let attempts = 0;
      let x, y, w, colour;
      while (attempts++ < 2) {
        x = getRandomInt(0,3);
        y = getRandomInt(0,4);
        w = getRandomInt(2,3);
        break;
      }
      if (itemCount < 7) {
        x = itemCount;
        y = itemCount;
        w = 2;
        colour = this.colours[itemCount];
      } else {
        x = 0;
        y = 6;
        w = 2;
        colour = this.colours[this.colours.length-1];
      }

      this.addItemAtLocation(x, y, w, colour);
    }
  }

  deleteItem() {
    if (this && this.state && this.state.currentInputId) {
      let update = {};
      update["layout." + this.state.currentInputId] = firebase.firestore.FieldValue.delete();
      this.updateDoc(update)
      .then(function() { console.log("Item successfully removed!"); });

      //console.log("clear chosen item from deleteitem");
      this.clearChosenItem();
    }
  }

  handleTextChange(inputType, i, editText) {
    var text = sanitizeHtml(editText, {
      allowedTags: [],
      allowedAttributes: []
    });

    if (inputType === "item") {
      this.updateLocation({i: i, text: text});
    } else if (inputType === "toprow") {
      this.updateFirstValue(i, text);
    }
  }

  handleItemColourChange(i, colour) {
    this.updateLocation({i: i, colour: colour});
    this.setState({lastColour: colour});
  }

  addFeedback(text, callback) {
    let newFeedbackId = getRandomInt(0, Number.MAX_SAFE_INTEGER).toString();
    let newFeedback = {text: text};

    let update = {};
    update[newFeedbackId] = newFeedback;
    this.updateFeedback(update)
    .then(function() { callback(); });
  }

  getItem(id) {
    return this.state.blob && this.state.blob.layout[id];
  }

  getItemIndex(id) {
    return this.state.blob && this.state.blob.layout.findIndex(e => {return e.i === id});
  }

  getText(id) {
    let item = this.getItem(id);
    if (item) {
      return item.text;
    }

    return "";
  }

  setChosenItemUnits(x, w, colour) {
    //console.log("set chosen item");
    this.setState({chosenItem: {x: x, w: w, colour: colour}});
  }

  clearChosenItem() {
    this.setState({chosenItem: null});
  }

  newButtonClicked() {
    this.addItem();
  }

  shareButtonClicked() {
    this.setState({showingShare: true});
  }

  onShareClose() {
    this.setState({showingShare: false});
  }

  deleteButtonClicked(e) {
    this.deleteItem();
    e.stopPropagation();
    e.preventDefault();
  }

  toggleTopRow() {
    this.updateTopRow(!this.state.blob.showTopRow);
  }

  backgroundClick() {
    console.log("background click");
    document.activeElement.blur();
    this.setState({
      currentInputType: null,
      currentInputId: null
    });
  }

  updateLocation({i, x, y, w, h, text, colour}) {
    ///console.log("update location", i, x, y, w, h);

    let newItems = {};
    if (text !== undefined) {
      newItems["layout." + i + ".text"] = text;
    } else if (colour !== undefined) {
      newItems["layout." + i + ".colour"] = colour;
    } else if (w !== undefined && h !== undefined) {
      newItems["layout." + i + ".x"] = x;
      newItems["layout." + i + ".y"] = y;
      newItems["layout." + i + ".w"] = w;
      newItems["layout." + i + ".h"] = h;
    } else {
      newItems["layout." + i + ".x"] = x;
      newItems["layout." + i + ".y"] = y;
    }

    this.updateDoc(newItems)
    .then(function() { console.log("Document successfully updated from updateLocation!", newItems); });
  }

  updateFirstValue(index, value) {
    let newItem = {};
    newItem["columnsFirstValues." + index] = value;

    this.updateDoc(newItem)
    .then(function() { console.log("Document successfully updated from updateFirstValue!", newItem); });
  }

  updateTopRow(value) {
    let newItem = {};
    newItem["showTopRow"] = value;

    this.updateDoc(newItem)
    .then(function() { console.log("Document successfully updated from updateTopRow!", value); });
  }

  setItemWidth(value) {
    this.setState({itemWidth: value});
  }

  render() {
    const selectedItem = this.state.currentInputType === "item" &&
      this.state.currentInputId;
    const selectedTopRowEntry = this.state.currentInputType === "toprow" &&
      this.state.currentInputId;
    const editingLocked = (this.state.blob && this.state.blob.locked) || this.state.showingShare;
    const showFeedback = this.state.blob &&
      (Object.keys(this.state.blob.layout).length> 3 || this.state.blob.locked) &&
      !this.state.showingShare;

    return (
      <div>
        <div className="top-bar">
          {this.state.blob && this.state.blob.locked && (
            <span style={{color: "white"}}>
              Welcome to this read-only view!
            </span>
          )}
          {this.state.blob && !editingLocked && (
            <div style={{float: "left"}}>
              <button style={{float: "left"}} className="newButton" onClick={this.newButtonClicked.bind(this)}>
                <div className="newButtonInternal">
                  +
                </div>
              </button>
              <button
                style={{
                  marginLeft: 20,
                  backgroundColor: "transparent",
                  color: "white",
                  borderColor: "#aaa",
                  borderStyle: "solid",
                  borderWidth: 1,
                  fontSize: 13,
                  float: "left"
                }}
                className="permalinkButton"
                onClick={this.shareButtonClicked.bind(this)}
              >
                Share
              </button>
              <div className="show-medium" style={{color: "#aaa", fontSize: 13, marginLeft: 20, float: "left", lineHeight: "29px"}}>
                Share this page for live collaboration!
              </div>
            </div>
          )}
          <div className="title" style={{paddingTop: 2}}>
            TimeFiddle
            &nbsp;
            <sup className="beta" style={{fontSize: 13}}>
              beta
            </sup>
          </div>
        </div>

        <div id="scrollContainer" style={{width: "100%", height: "100vh", overflow: "auto"}}>
          {this.state.blob && this.state.blob.showTopRow &&
            <TopRow
              cols={this.props.cols}
              columnsFirstValues={this.state.blob.columnsFirstValues}
              selectedEntry={selectedTopRowEntry}
              clearSelect={this.clearSelect.bind(this)}
              onTopRowMouseUp={this.onTopRowMouseUp.bind(this)}
              handleTextChange={this.handleTextChange.bind(this)}
              locked={editingLocked}
              itemWidth={this.state.itemWidth}
              setItemWidth={this.setItemWidth.bind(this)}
              chosenItem={this.state.chosenItem}
            />
          }
          {this.state.blob && (
            <Grid
              layout={this.state.blob.layout}
              handleTextChange={this.handleTextChange.bind(this)}
              handleItemColourChange={this.handleItemColourChange.bind(this)}
              onMouseUp={this.onMouseUp.bind(this)}
              updateLocation={this.updateLocation.bind(this)}
              showCounter={this.state.showCounter}
              deleteButtonClicked={this.deleteButtonClicked.bind(this)}
              selectedItem={selectedItem}
              clearSelect={this.clearSelect.bind(this)}
              newButtonClicked={this.newButtonClicked.bind(this)}
              addItemAtLocation={this.addItemAtLocation.bind(this)}
              addFeedback={this.addFeedback.bind(this)}
              showFeedback={showFeedback}
              locked={editingLocked}
              itemWidth={this.state.itemWidth}
              colours={this.colours}
              cols={this.props.cols}
              setChosenItemUnits={this.setChosenItemUnits.bind(this)}
              clearChosenItem={this.clearChosenItem.bind(this)}
            />
          )}
          {this.state.showingShare && (
            <Share
              newId={App.newId}
              setDocWithId={this.setDocWithId.bind(this)}
              onShareClose={this.onShareClose.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default App;
