import React from "react";

export default class Palette extends React.PureComponent {

  setColour(c, e) {
    this.props.setColour(c);
    e.stopPropagation();

    // comment this out if we want to make palette vanish immediately.
    e.preventDefault();
  }

  render() {
    return (
      <div
        className="arrow_box"
        style={{
          marginLeft: 4,
          position: "absolute",
          marginTop: 7,
          padding: 6,
          paddingLeft: 0,
          paddingTop: 0
        }}
      >
        {this.props.colours.map((c, i) => (
          <div
            key={i}
            style={{
              backgroundColor: c,
              width: 25,
              height: 25,
              float: "left",
              marginLeft: 6,
              marginTop: 6,
              cursor: "pointer",
              borderRadius: 3,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: c === "#f5f5f5" ? "#a3a3a3" : c,
              boxSizing: "border-box"
            }}
            onMouseDown={this.setColour.bind(this, c)}
            onTouchStart={this.setColour.bind(this, c)}
          >
          </div>
        ))}
        <button
          style={{
            marginLeft: 20,
            backgroundColor: "rgb(62, 62, 62)",
            color: "white",
            borderColor: "rgb(62, 62, 62)",
            borderStyle: "solid",
            borderWidth: 1,
            fontSize: 13,
            float: "left",
            marginTop: 12,
            marginLeft: 6,
            width: "calc(100% - 6px)"
          }}
          className="permalinkButton"
          onMouseDown={this.props.deleteButtonClicked}
          onTouchStart={this.props.deleteButtonClicked}
        >
          Delete
        </button>
      </div>
    );
  }
};

