import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// from https://stackoverflow.com/a/49348134/2001864

class Draggable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relX: 0,
      relY: 0,
      dragType: "none"
    };
    this.gridX = props.gridX || 1;
    this.gridY = props.gridY || 1;
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  }

  static propTypes = {
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragStop: PropTypes.func,
    minx: PropTypes.number.isRequired,
    maxx: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    gridX: PropTypes.number,
    gridY: PropTypes.number,
    minWidth: PropTypes.number.isRequired,
  };

  onDragStart(e) {
    const ref = ReactDOM.findDOMNode(this.handle);
    const body = document.getElementById('scrollContainer'); // ReactDOM.findDOMNode(this).parentNode; //this.props.scrollContainer;
    const box = ref.getBoundingClientRect();
    let dragType = "move";
    if (this.props.resizable) {
      if (box.right - e.pageX < 20) {
        dragType = "right";
      } else if (e.pageX - box.left < 20) {
        dragType = "left";
      }
    }

    // relX/Y is how many pixels into the bar the user gripped onto.
    this.setState({
      relX: e.pageX - (box.left - body.offsetLeft - body.clientLeft + window.pageXOffset),
      relY: e.pageY - (box.top - body.offsetTop - 34 - body.clientTop + window.pageYOffset),
      dragType: dragType
    });

    this.props.onDragStart && this.props.onDragStart();
  }

  onDragMove(e) {
    const body = document.getElementById('scrollContainer'); // ReactDOM.findDOMNode(this).parentNode; //this.props.scrollContainer;
    let minx = this.props.minx;
    let maxx = this.props.maxx;
    let w = maxx-minx + 1;
    let y;
    if (this.state.dragType === "left") {
      minx = e.pageX + body.scrollLeft;
      if (minx + this.props.minWidth - 1 >= maxx) {
        minx = maxx - this.props.minWidth;
      }
      y = this.props.y;
    } else if (this.state.dragType === "right") {
      maxx = e.pageX + body.scrollLeft;
      if (maxx - minx + 1 < this.props.minWidth) {
        maxx = minx + this.props.minWidth - 1;
      }
      y = this.props.y;
    } else if (this.state.dragType === "move") {
      minx = e.pageX - this.state.relX + body.scrollLeft;
      if (minx < 0) {
        minx = 0;
      }
      maxx = minx + w - 1;
      y = e.pageY - this.state.relY + body.scrollTop;
      if (y < 0) {
        y = 0;
      }
    }

    this.props.onDragMove && this.props.onDragMove(minx, maxx, y, this.state.dragType);
  }

  onDragStop(e) {
    this.props.onDragStop && this.props.onDragStop(this.state.x, this.state.y);
  }

  onMouseDown(e) {
    if (e.button !== 0) return;
    this.onDragStart(e);
    document.addEventListener('mousemove', this.onMouseMove, {passive: false});
    document.addEventListener('mouseup', this.onMouseUp, {passive: false});
    e.preventDefault();
  }

  onMouseUp(e) {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.onDragStop(e);
    e.preventDefault();
    e.stopPropagation();
  }

  onMouseMove(e) {
    this.onDragMove(e);
    e.preventDefault();
    e.stopPropagation();
  }

  onTouchStart(e) {
    this.onDragStart(e.touches[0]);
    document.addEventListener('touchmove', this.onTouchMove, {passive: false});
    document.addEventListener('touchend', this.onTouchEnd, {passive: false});
    e.preventDefault();
  }

  onTouchMove(e) {
    this.onDragMove(e.touches[0]);
    e.preventDefault();
  }

  onTouchEnd(e) {
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
    this.onDragStop(e);
    e.preventDefault();
  }

  onContextMenu(e) {
    this.props.onContextMenu();
    e.preventDefault();
  }

  render() {
    return <div
      onMouseDown={!this.props.locked ? this.onMouseDown : undefined}
      onTouchStart={!this.props.locked ? this.onTouchStart : undefined}
      onContextMenu={!this.props.locked ? this.onContextMenu.bind(this) : undefined}
      style={{
        position: 'absolute',
        left: this.props.minx,
        top: this.props.y,
        touchAction: 'none',
        zIndex: this.props.zIndex,
        opacity: this.props.opacity
      }}
      ref={(div) => { this.handle = div; }}
    >
      {this.props.children}
    </div>;
  }
}

export default Draggable;
