import React from "react";
import Draggable from "./Draggable";
import {itemHeight} from "./Utils";
import GridItemInput from "./GridItemInput";
import Palette from "./Palette";

export default class GridItem extends React.PureComponent {
  state = {
    showWhenCounterHits: 0
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  snap(n, m, bias) {
    return Math.round((n + m * bias) / m) * m;
  }

  onDragStart() {
    this.setState({
      dragging: true,
      x: this.props.x * this.props.itemWidth,
      y: this.props.y * itemHeight,
      width: this.props.w * this.props.itemWidth
    });
    if (this.props.selected) {
      // We should be using a forwardRef but no luck making it work yet.
      this.inputRef.current.ref.select();
    } else {
      this.props.clearSelect();
    }
    //console.log("setchosen from dragstart");
    this.props.setChosenItemUnits(this.props.x, this.props.w, this.props.colour);
  }

  onDragMove(minx, maxx, y, dragType) {
    this.setState({x: minx, y: y, width: maxx-minx+1, dragType: dragType});

    let [newX, newWidth, ] = this.getItemDestinationUnits(minx, maxx, maxx-minx+1, y);
    //console.log("setchosen from ondragmove");
    this.props.setChosenItemUnits(newX, newWidth, this.props.colour);
  }

  onDragStop() {
    let srcX = this.state.x;
    let srcMaxX = this.state.x + this.state.width - 1;
    let w = srcMaxX - srcX + 1;
    let y = this.snap(this.state.y, itemHeight, 0);

    let [newX, newWidth, newY] = this.getItemDestinationUnits(srcX, srcMaxX, w, y);

    if (newX === this.props.x && newY === this.props.y && newWidth === this.props.w) {
      this.setState({ dragging: false });
      this.props.onMouseUp(this.props.i);
    } else {
      this.setState({
        dragging: false,
        showWhenCounterHits: this.props.showCounter + 1
      });

      this.props.updateLocation({
        i: this.props.i,
        x: newX,
        y: newY,
        w: newWidth,
        h: this.props.h
      });

      //console.log("clearchosen from dragstop");
      this.props.clearChosenItem();
    }
  }

  onContextMenu() {
    this.props.onMouseUp(this.props.i);
  }

  saveEdit(text) {
    this.props.handleTextChange("item", this.props.i, text);
    this.props.clearSelect();
    //console.log("clearchosen from saveedit");
    this.props.clearChosenItem();
  }

  onItemKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.saveEdit(e.target.value);
    }
  }

  onItemBlur(value) {
    this.saveEdit(value);
  }

  setColour(colour) {
    this.props.handleItemColourChange(this.props.i, colour);
  }

  getItemDestinationPixels(minx, maxx, w) {
    let snapBias;
    if (this.state.dragType === "left") {
      snapBias = -0.25;
    } else if (this.state.dragType === "right") {
      snapBias = 0.25;
    } else {
      snapBias = 0;
    }
    let shadowX = this.snap(minx, this.props.itemWidth, snapBias);
    let shadowMaxX;
    if (this.state.dragType === "move") {
      shadowMaxX = shadowX + w;
    } else {
      shadowMaxX = this.snap(maxx, this.props.itemWidth, snapBias) - 1;
    }
    let shadowW = shadowMaxX - shadowX + 1;

    return [shadowX, shadowMaxX, shadowW];
  }

  getItemDestinationUnits(minx, maxx, w, y) {
    let [destX, , destWidth] = this.getItemDestinationPixels(minx, maxx, w);

    let newX = Math.round(destX / this.props.itemWidth);
    let newWidth = Math.round(destWidth / this.props.itemWidth);
    let newY = Math.round(y / itemHeight);

    if (newWidth < 0) {
      newWidth = 1;
    }

    if (newX < 0) {
      newX = 0;
    }

    // If 30 >= 30, newX = 30 - 1 = 29.
    if (newX >= this.props.cols) {
      newX = this.props.cols - 1;
    }

    // If 29 + 1 - 1 >= 30, newWidth = 30 - 29 = 1
    if (newX + newWidth - 1 >= this.props.cols) {
      newWidth = this.props.cols - newX;
    }

    return [newX, newWidth, newY];
  }

  render() {
    let minx, maxx, y, w;
    let shadowX, shadowY, shadowW;

    const h = this.props.h * itemHeight;

    if (this.state.dragging) {
      // when dragging, then use our state's x/y.
      minx = this.state.x;
      maxx = this.state.x + this.state.width - 1;
      y = this.state.y;
      w = maxx - minx + 1;

      [shadowX, shadowY, shadowW] = this.getItemDestinationPixels(minx, maxx, w);

      shadowY = this.snap(y, itemHeight, 0);

      //console.log(minx, maxx, w, this.state.width, "-", shadowX, shadowW);
    } else {
      minx = this.props.x * this.props.itemWidth;
      maxx = minx + w - 1;
      y = this.props.y * itemHeight;
      w = this.props.w * this.props.itemWidth;
    }

    const showItem = this.props.showCounter >= this.state.showWhenCounterHits;
    const opacity = showItem ? 1 : 0;

    let backgroundColour = this.props.colour;
    let borderColour = this.props.colour;
    let colour = "white";

    if (backgroundColour === "#f5f5f5") {
      borderColour = "#a3a3a3";
      colour = "#3e3d3d";
    }

    return (
      <div>
        {this.state.dragging && (
          <div
            className="item"
            style={{
              position: "absolute",
              backgroundColor: "#cecaca",
              width: shadowW,
              height: h,
              left: shadowX,
              top: shadowY,
              boxSizing: "border-box",
              opacity: 0.7
            }}
          >
            <span>&nbsp;</span>
          </div>
        )}

        <Draggable
          minx={minx}
          maxx={maxx}
          y={y}
          onDragStart={this.onDragStart.bind(this)}
          onDragMove={this.onDragMove.bind(this)}
          onDragStop={this.onDragStop.bind(this)}
          onContextMenu={this.onContextMenu.bind(this)}
          minWidth={this.props.itemWidth}
          locked={this.props.locked}
          resizable={true}
          zIndex={this.props.selected ? 10 : 0}
          opacity={opacity}
        >
          <div>
            <div
              className="item"
              spellCheck="false"
              style={{
                top: 1,
                left: 1,
                backgroundColor: backgroundColour,
                color: colour,
                borderColor: borderColour,
                borderWidth: 1,
                borderStyle: "solid",
                width: w - 2,
                height: h - 2,
                boxSizing: "border-box",
                cursor: this.props.locked ? "auto" : undefined,
                opacity: 0.9
              }}
            >
              {!this.props.selected && this.props.text}
              {this.props.selected && (
                <span>&nbsp;</span>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                userSelect: "none",
                cursor: "col-resize",
                top: 0,
                right: 0,
                width: 10,
                height: itemHeight
              }}
            />
            <div
              style={{
                position: "absolute",
                userSelect: "none",
                cursor: "col-resize",
                top: 0,
                left: 0,
                width: 10,
                height: itemHeight
              }}
            />
            {(false || this.props.selected) && (
              <div>
                <GridItemInput
                  text={this.props.text}
                  w={w}
                  onItemKeyDown={this.onItemKeyDown.bind(this)}
                  onBlur={this.onItemBlur.bind(this)}
                  ref={this.inputRef}
                  colour={colour}
                />
                <Palette
                  setColour={this.setColour.bind(this)}
                  colours={this.props.colours}
                  deleteButtonClicked={this.props.deleteButtonClicked}
                />
              </div>
            )}
          </div>
        </Draggable>
      </div>
    );
  }
}
