import React from "react";
import Draggable from "./Draggable";

var moment = require('moment');
var parseFormat = require('moment-parseformat')

export default class TopRow extends React.PureComponent {
  getColumnsValueDiff() {
    return parseInt(this.props.columnsFirstValues[1], 10) -
           parseInt(this.props.columnsFirstValues[0], 10);
  }

  saveEditForItems(text0, text1) {
    this.props.handleTextChange("toprow", 0, text0);
    this.props.handleTextChange("toprow", 1, text1);
    this.props.clearSelect();
  }

  saveEditForItem(text, entry) {
    this.props.handleTextChange("toprow", entry, text);
    this.props.clearSelect();
  }

  saveEdit(text) {
    this.saveEditForItem(text, this.props.selectedEntry);
  }

  onItemKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.saveEdit(e.target.value);
    }
  }

  onItemBlur(e) {
    this.saveEdit(e.target.value);
  }

  isDigit(c) {
    return '0123456789'.indexOf(c) !== -1;
  }

  // If we work backwards from the end and find all digits, then we know
  // how many characters before that are non-digits.  Then count forward through the
  // date formatted string to see if we match.  If we do, then we know the date parser
  // didn't find any viable date text before hitting those final digits.

  getValueStringAndNumber(value, format) {
    if (!value || !format) {
      return [false];
    }

    if (!this.isDigit(value[value.length-1])) {
      return [false];
    }

    // Find the offset of the number at the end of the string.
    let offsetFinalNumber = -1;
    for (var c = value.length-1; c >= 0; c--) {
      if (this.isDigit(value[c])) {
        offsetFinalNumber = c;
      } else {
        break;
      }
    };

    if (value.substring(0, offsetFinalNumber) ===
        format.substring(0, offsetFinalNumber)) {
      // The strings otherwise match, which means that momentjs didn't find any
      // dates in this string (other than maybe something for the single number at
      // the end).
      let baseString = value.substring(0, offsetFinalNumber);
      let number = parseInt(value.substring(offsetFinalNumber, value.length));
      return [true, baseString, number];
    }
    return [false];
  }

  analyzeFirstValue(i) {
    let firstValue = {};
    let lastMoment;

    if (i > 1) {
      console.log("bad parameter to getFirstValue!");
    }

    firstValue = {};

    firstValue.format = parseFormat(this.props.columnsFirstValues[i]);

    let [stringAndNumber, nonDateText, nonDateNumber] =
      this.getValueStringAndNumber(this.props.columnsFirstValues[i], firstValue.format);

    if (stringAndNumber) {
      // A single digit.  It's either a string + digit or just a digit.
      firstValue.type = "number";
      firstValue.baseText = nonDateText;
      firstValue.baseNumber = nonDateNumber;
    } else {
      firstValue.type = "moment";
      firstValue.moment = moment(this.props.columnsFirstValues[i], firstValue.format);
      lastMoment = firstValue.moment.clone();
    }

    return [firstValue, lastMoment];
  }

  getExtrapolatedValue(i, firstValues, lastMoment) {
    let value;
    let first, second;

    if (i <= 1) {
      console.log("bad parameter to getValue");
    }

    if (firstValues[0].type === firstValues[1].type &&
        firstValues[0].type === "moment" &&
        firstValues[0].moment.isValid() && firstValues[1].moment.isValid() &&
        firstValues[0].format === firstValues[1].format) {
      first = firstValues[0].moment;
      second = firstValues[1].moment;

      // If we have difference in years, let's use that.
      // If we have difference in months, let's use that.
      // Otherwise let's go for days.
      // The downside is that if you wanted to do something like
      // every 6 weeks, we're going to just round down to the nearest
      // month.
      var duration = moment.duration(second.diff(first, "years"), "years");
      if (duration.years() === 0) {
        duration = moment.duration(second.diff(first, "months"), "months");
        if (duration.months() === 0) {
          duration = moment.duration(second.diff(first, "days"), "days");
          if (duration.days() === 0) {
            duration = moment.duration(second.diff(first, "hours"), "hours");
            if (duration.hours() === 0) {
              duration = moment.duration(second.diff(first, "minutes"), "minutes");
            }
          }
        }
      }

      var newMoment = lastMoment.add(duration);
      value = newMoment.format(firstValues[0].format);
      lastMoment = newMoment.clone();

    } else if (firstValues[0].type === firstValues[1].type &&
      firstValues[0].type === "number" &&
      firstValues[0].baseText === firstValues[1].baseText) {

      first = firstValues[0].baseNumber;
      second = firstValues[1].baseNumber;

      value = firstValues[0].baseText +
        (firstValues[0].baseNumber + i * (firstValues[1].baseNumber - firstValues[0].baseNumber));
    } else {
      value = "" + (parseInt(this.props.columnsFirstValues[0], 10) + i * this.getColumnsValueDiff());
      value = (isNaN(value) || value === "") ? null : value;
    }

    return [value, lastMoment];
  }

  onDragMove(columnIndex, minx, maxx, y) {
    if (minx < columnIndex * 40) {
      minx = columnIndex * 40;
    }
    if (minx > columnIndex * 120) {
      minx = columnIndex * 120;
    }

    this.props.setItemWidth(minx / columnIndex);
  }

  render() {
    let analyzedFirstValues = [];
    let lastMoment;
    let value;
    var items = [];

    //console.log("toprow render");

    for (var i = 0; i < this.props.cols; i++) {
      let className = "top-row-static";
      if (i <= 1) {
        className += " top-row-static-first";
        [analyzedFirstValues[i], lastMoment] = this.analyzeFirstValue(i);
        value = this.props.columnsFirstValues[i];
      } else {
        [value, lastMoment] = this.getExtrapolatedValue(i, analyzedFirstValues, lastMoment);
      }

      var itemSelected =
        this.props.selectedEntry !== undefined &&
        this.props.selectedEntry === i;

      let helpTopText, helpOptions = [];

      let itemChosen =
        this.props.chosenItem &&
        i >= this.props.chosenItem.x &&
        i <= this.props.chosenItem.x + this.props.chosenItem.w - 1;

      if (i === 0) {
        helpTopText = "Try one of these, or type your own!";
        helpOptions = [
          {text0: moment().format("dddd"), text1: moment().add(1, "d").format("dddd")},
          {text0: moment().format("MMMM"), text1: moment().add(1, "M").format("MMMM")},
          {text0: moment().format("MMM D"), text1: moment().add(1, "d").format("MMM D")},
          {text0: moment().format("YYYY"), text1: moment().add(1, "y").format("YYYY")},
          {text0: "Week 1", text1: "Week 2"},
        ];
      } else if (i === 1) {
        helpTopText = "Try typing the next item in your sequence.";
      }

      if (i === 2 &&
          (!value || value === "") &&
          (this.props.selectedEntry !== 0 && this.props.selectedEntry !== 1)) {
        items.push(
          <div key={i} style={{position: "relative", float: "left"}}>
            <div
              className={className}
              key={i}
              style={{paddingLeft: 10, paddingRight: 10}}
            >
              Make sure the first two columns start a sequence.
            </div>
          </div>
        );
      }

      if (i <= 1 || (value && value !== "")) {
        let chosenColour;
        if (itemChosen) {
          if (this.props.chosenItem.colour === "#f5f5f5") {
            chosenColour = "#3e3d3d";
          } else {
            chosenColour = this.props.chosenItem.colour;
          }
        }

        items.push(
          <div key={i} style={{position: "relative", float: "left"}}>
            <div
              className={className}
              key={i}
              style={{width: this.props.itemWidth, cursor: this.props.locked ? "auto" : undefined}}
              onClick={!this.props.locked ? this.props.onTopRowMouseUp.bind(this, i) : undefined}
            >
              <div style={{marginLeft: 4, marginRight: 4, overflow: "hidden"}}>
                {(itemSelected || value === "") && (
                  <span>&nbsp;</span>
                )}
                {!itemSelected && value}
              </div>
              {itemChosen && (
                <div style={{backgroundColor: chosenColour, width: "100%", height: 2}}>
                </div>
              )}
            </div>

            {itemSelected && (
              <div>
                <input
                  autoFocus
                  spellCheck="false"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "arial",
                    fontSize: 16,
                    background: "none",
                    position: "absolute",
                    color: "#3e3d3d",
                    top: 7,
                    left: -1,
                    border: "none",
                    marginTop: 0,
                    marginLeft: 0
                  }}
                  type="text"
                  defaultValue={value}
                  onKeyDown={this.onItemKeyDown.bind(this)}
                  onBlur={this.onItemBlur.bind(this)}
                />

                <div
                  className="arrow_box"
                  style={{
                    marginLeft: 4,
                    position: "absolute",
                    marginTop: 5,
                    top: 34,
                    zIndex: 40,
                    whiteSpace: "pre",
                    fontSize: 13
                  }}
                >
                  <div style={{padding: 10}}>
                    {i === 0 && (
                      <div>
                        <div style={{marginBottom: 8}}>{helpTopText}</div>
                        <div style={{marginLeft: 12, marginRight: 12, fontSize: 13, cursor: "pointer", textAlign: "center"}}>
                          {helpOptions.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              style={{marginBottom: 4, backgroundColor: "#cecaca", padding: 4}}
                              onMouseDown={this.saveEditForItems.bind(this, option.text0, option.text1)}
                            >
                              {option.text0}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {i === 1 && (
                      <div>{helpTopText}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }

      if (i === 0 || i === 1) {
        let w = 2;
        let columnIndex = i+1;
        let minx = columnIndex * this.props.itemWidth - 2;
        let maxx = minx + w - 1;
        let y = 0;

        items.push(
          <Draggable
            minx={minx}
            maxx={maxx}
            y={y}
            onDragMove={this.onDragMove.bind(this, columnIndex)}
            minWidth={w}
            locked={this.props.locked}
            key={"dragnotch" + i}
            resizable={false}
          >
            <div
              style={{
                position: "relative",
                float: "left",
                width: 4,
                backgroundColor: "auto",
                zIndex: 200,
                cursor: "col-resize",
                top: 4,
                height: 26
              }}
            >
              &nbsp;
            </div>
          </Draggable>
        );
      }
    }

    return (
      <div className="top-row" style={{width: this.props.itemWidth * 30}}>
        {items}
      </div>
    );
  }
};
