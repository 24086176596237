import React from "react";

export default class GridItemInput extends React.PureComponent {
  componentDidMount() {
    this.ref.focus();
  }

  onBlur(e) {
    this.props.onBlur(e.target.value);
  }

  render() {
    return (
      <div>
        <input
          autoFocus
          spellCheck="false"
          style={{
            caretColor: "white",
            fontFamily: "arial",
            fontSize: 16, width: this.props.w - 42,
            background: "none",
            position: "absolute",
            color: this.props.colour,
            top: 0,
            border: "none",
            marginTop: 10,
            marginLeft: 18
          }}
          type="text"
          defaultValue={this.props.text}
          onKeyDown={this.props.onItemKeyDown}
          onBlur={this.onBlur.bind(this)}
          ref={(input) => {this.ref = input}}
        />
        {false && (
          <div
            className="deleteitem"
            onMouseDown={this.props.deleteButtonClicked}
            onTouchStart={this.props.deleteButtonClicked}
          >
            x
          </div>
        )}
      </div>
    );
  }
};

