import React from "react";
import GridItem from "./GridItem";
import Feedback from "./Feedback";
import {itemHeight} from "./Utils";

export default class Grid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
  }

  onDoubleClick(e) {
    if (e.target === this.gridRef.current) {
      const body = document.getElementById('scrollContainer');
      let newX = Math.floor((body.scrollLeft + e.clientX - body.offsetLeft) / this.props.itemWidth);
      let newY = Math.floor((body.scrollTop + e.clientY - 34 - body.offsetTop) / itemHeight);

      this.props.addItemAtLocation(newX, newY, 1);
    }
  }

  render() {
    let items = [];

    Object.keys(this.props.layout).forEach(key => {
      let e = this.props.layout[key];
      let item =
        <GridItem
          key={key}
          i={key}
          text={e.text}
          colour={e.colour || "#3874e2"}
          x={e.x}
          y={e.y}
          w={e.w}
          h={e.h}
          handleTextChange={this.props.handleTextChange}
          handleItemColourChange={this.props.handleItemColourChange}
          onMouseUp={this.props.onMouseUp}
          updateLocation={this.props.updateLocation}
          showCounter={this.props.showCounter}
          deleteButtonClicked={this.props.deleteButtonClicked}
          selected={this.props.selectedItem && this.props.selectedItem === key}
          clearSelect={this.props.clearSelect}
          locked={this.props.locked}
          itemWidth={this.props.itemWidth}
          colours={this.props.colours}
          cols={this.props.cols}
          setChosenItemUnits={this.props.setChosenItemUnits}
          clearChosenItem={this.props.clearChosenItem}
        />;

      items.push(item);
    });

    return (
      <div
        style={{width: 30 * this.props.itemWidth, height: 2000, position: "relative"}}
        onDoubleClick={this.onDoubleClick.bind(this)}
        ref={this.gridRef}
      >
        {items}
        {(items.length === 0 && !this.props.locked) && (
          <div
            style = {{
              fontSize: 16,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              lineHeight: "40px",
              textAlign: "center"
            }}
          >
            Welcome! :)
            &nbsp;
            Press the
            &nbsp;
            <button className="newButton" onClick={() => {this.props.newButtonClicked()}} style={{float: "none"}}>
              <div className="newButtonInternal">
                +
              </div>
            </button>
            &nbsp;
            to add your first
            &nbsp;
            <span className="item" style={{cursor: "auto", backgroundColor: "#3874e2"}}>
              block of time
            </span>
          </div>
        )}

        {this.props.showFeedback && (
          <Feedback addFeedback={this.props.addFeedback}/>
        )}
      </div>
    );
  }
}