import React from "react";

export default class Grid extends React.PureComponent {
  state = {
    state: "none"
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  onClick() {
    this.setState({state: "showing"});
  }

  onClose() {
    this.setState({state: "none"});
  }

  handleSubmit(e) {
    this.setState({state: "submitting"});
    this.props.addFeedback(this.input.current.value, () => {
      this.setState({state: "submitted"});
      setTimeout(() => {this.setState({state: "none"})}, 3000);
    });
    e.preventDefault();
  }

  render() {
    if (this.state.state === "submitted" || this.state.state === "none") {
      return (
        <div
          style = {{
            fontSize: 13,
            position: "fixed",
            bottom: 5,
            right: 5,
            cursor: "pointer",
            textAlign: "center",
            color: "rgb(62, 62, 62)",
            backgroundColor: "rgba(255,255,255,0.9)",
            padding: 10,
            borderRadius: 3
          }}
          onClick={this.onClick.bind(this)}
        >
          <img src={process.env.PUBLIC_URL + '/bubble.svg'} style={{width: 40}} alt=""/>
          <br/>
          {this.state.state === "submitted" && (
            <div>
              Thank you!
            </div>
          )}
          {this.state.state === "none" && (
            <div>
              Tell us what you think!
            </div>
          )}
        </div>
      );
    } else if (this.state.state === "showing" || this.state.state === "submitting"){
      return (
        <div
          style = {{
            backgroundColor: "rgb(62, 62, 62)", // "#1d9066",
            padding: 10,
            borderRadius: 10,
            fontSize: 13,
            position: "fixed",
            bottom: 20,
            right: 20,
            width: "30%",
            minWidth: 200,
            color: "white"
          }}
        >
          <form onSubmit={this.handleSubmit.bind(this)}>
            Tell us here at TimeFiddle what you think!
            <br/>
            <br/>
            Include your email address if you'd like a reply.
            <br/>
            <br/>
            <textarea
              autoFocus
              name="body"
              rows={4}
              onChange={this.handleChange}
              style={{resize: "none", width: "100%", boxSizing: "border-box"}}
              ref={this.input}
            />
            <br/>
            <br/>
            <input
              type="submit"
              value="Submit"
              style={{
                float: "right",
                cursor: "pointer",
                backgroundColor: "transparent",
                color: "white",
                fontSize: 13,
                padding: 7,
                borderColor: "#aaa",
                borderRadius: 3,
                borderStyle: "solid",
                borderWidth: 1
              }}
              disabled={this.state.state === "submitting"}
            />
            <div
              className="deleteitem"
              onClick={this.onClose.bind(this)}
            >
              x
            </div>
          </form>
        </div>
      );
    }
  }
}
