import React from "react";

export default class Share extends React.PureComponent {
  state = {
    state: "none",
    copied: false
  };

  componentDidMount() {
    let newId = "v-" + this.props.newId();
    this.props.setDocWithId(newId, this.success.bind(this), this.failure.bind(this));
    this.setState({id: newId, state: "showing"});
  }

  onClose() {
    this.props.onShareClose();
  }

  success() {

  }

  failure() {

  }

  copyClicked() {
    this.setClipboard(window.location.origin + "/" + this.state.id);
  }

  setClipboard(text) {
    let data = new DataTransfer();

    data.items.add("text/plain", text);
    let that = this;
    navigator.clipboard.writeText(text).then(function() {
      that.setState({copied: true})
    }, function() {
      /* failure */
    });
  }

  render() {
    if (this.state.state === "showing") {
      return (
        <div
          style = {{
            backgroundColor: "rgb(62, 62, 62)", // "#1d9066",
            padding: 30,
            borderRadius: 10,
            fontSize: 13,
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            minWidth: 250,
            color: "white"
          }}
        >
          <div>
            Here's a permanent link to this snapshot of the current TimeFiddle:
          </div>

          <div style={{margin: 20, marginTop: 10, marginLeft: 10, overflow: "hidden"}}>
            <div
              style={{
                float: "left",
                userSelect: "all",
                backgroundColor: "white",
                padding: 10,
                color: "#3e3d3d",
                marginTop: 10,
                marginLeft: 10
              }}>
              {window.location.origin + "/" + this.state.id}
            </div>
            {!this.state.copied && (
              <div
                style={{
                  float: "left",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 9,
                  paddingBottom: 9,
                  cursor: "pointer",
                  color: "#ddd",
                  height: 15,
                  borderStyle: "solid",
                  borderRadius: 3,
                  borderColor: "#aaa",
                  borderWidth: 1,
                  marginLeft: 10,
                  marginTop: 10,
                }}
                onClick={this.copyClicked.bind(this)}
              >
                Copy
              </div>
            )}
            {this.state.copied && (
              <div style={{float: "left", padding: 10, marginLeft: 11, marginTop: 10, color: "#ddd"}}>
                Copied
              </div>
            )}
          </div>

          <div style={{clear: "both"}}>
            If you want to allow other people to edit this TimeFiddle at the same time,
            just share this page's address.  You're already in an edit session!
          </div>
          <div
            className="deleteitem"
            onClick={this.props.onShareClose}
          >
            x
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
